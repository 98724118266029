@use "../../../../assets/styleUtils/index" as *;

.software-info {
  width: 100%;
//   padding-top: 120px;
  padding-bottom: 180px;
  position: relative;
  @include mq(968px) {
    margin-top: 320px;
  }
  @include mq(768px) {
    margin-top: 220px;
  }
  @include mq(668px) {
    margin-top: unset;
  }
  @include mq(568px) {
    padding-top: 60px;
  }
  @include mq(468px) {
    padding-top: 20px;
  }

  .container {
    width: 85%;
    position: relative;
    z-index: 1;
    display: flex;
    margin: auto;
    margin-bottom: 90px;
    @include mq(768px) {
      width: 95%;
      margin-bottom: 30px;
    }
    .left,
    .right {
      flex-basis: 50%;
      @include mq(768px) {
        flex-basis: 100%;
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0 70px;
    }
    .title {
      font-weight: 700;
      font-size: 55px;
      font-weight: bold;
    }
    .content {
      font-weight: 400;
      font-size: 18px;
      line-height: 163.15%;
    }
    button {
      align-self: flex-start;
      padding: 15px 20px;
      background: #28a84a;
      box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
      border: none;
      border-radius: 10px;
      color: white;
    }
  }
}
