@use "../../../../assets/styleUtils/index" as *;

.pd_about {
  width: 100%;
  padding-left: 7.5%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(970px) {
    padding: 0 2.5%;
    flex-direction: column;
  }
  .text_about {
    width: 30%;
    @include mq(970px) {
      width: 100%;
    }
    .title {
      font-weight: 700;
      font-size: 55px;
      display: flex;
      align-items: center;
      color: var(--darkest_gray);
      margin-bottom: 48px;
      @include mq(768px) {
        font-size: 40px;
      }
    }
    .message {
      font-weight: 400;
      font-size: 18px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: var(--darkest_gray);
      @include mq(768px) {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
  .image_about_side {
    width: 600px;
    position: relative;
    @include mq(970px) {
      width: 400px;
      margin-top: 30px;
    }
    @include mq(680px) {
      width: 300px;
    }
    @include mq(450px) {
      width: 100%;
    }
    .base {
      top: -25px;
      right: 260px;
      width: 50px;
      height: 50px;
      background-color: var(--brand_green);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      @include mq(970px) {
        width: 40px;
        height: 40px;
      }
      @include mq(680px) {
        right: 160px;
        width: 25px;
        height: 25px;
        top: -15px;
        img {
          width: 100%;
        }
      }
    }
    .base,
    .critical,
    .alarm {
      position: absolute;
      z-index: 1;
      // @include mq(970px) {
      //   display: none;
      // }
    }

    .critical {
      top: 60px;
      left: -130px;
      width: 250px;
      @include mq(970px) {
        width: 200px;
      }
      @include mq(680px) {
        width: 150px;
        left: -70px;
      }
      @include mq(450px) {
        left: 20px;
      }
    }
    .alarm {
      bottom: 60px;
      left: -40px;
      width: 60px;
      height: 60px;
      background: var(--brand_green);
      @include mq(970px) {
        width: 40px;
        height: 40px;
        left: -20px;
      }
      @include mq(450px) {
        left: 20px;
      }
      .cover_red {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        img {
          width: 30px;
        }
        p {
          position: absolute;
          top: -10px;
          right: -10px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--authoritative_red);
          color: white;
          border-radius: 50%;
          @include mq(970px) {
            height: 20px;
            width: 20px;
            font-size: 13px;
          }
        }
      }
    }

    filter: drop-shadow(0px 15px 50px rgba(0, 0, 0, 0.1));
    img.dashboard {
      width: 100%;
      object-fit: fill;
    }
  }
}
