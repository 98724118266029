@use "../../../../assets/styleUtils/index" as *;
.pd_review {
  background-color: var(--darkest_gray);
  width: 100%;
  margin-top: 30px;
  padding: 60px 0;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 150px;
  .abs {
    position: absolute;
    right: 0;
  }
  .cover_review {
    display: flex;
    width: 85%;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    .first_text {
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: var(--white);
      @include mq(700px) {
        margin-bottom: 20px;
      }
    }
    .sec_text {
      @include mq(700px) {
        margin-bottom: 20px;
      }
      .mill {
        font-weight: 800;
        font-size: 52px;
        line-height: 52px;
        letter-spacing: -0.02em;
        margin-bottom: 20px;
        color: white;
        /* White */
      }
      .user {
        display: flex;
        align-items: center;
        img {
          width: 20px;
        }
        p {
          font-weight: 400;
          font-size: 25px;
          line-height: 26px;
          letter-spacing: -0.02em;
          margin-left: 20px;
          color: var(--white);
        }
      }
    }
  }
}
