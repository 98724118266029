:root {
  --brand_green: #28a84a;
  --footer_blue: #1e1e2d;
  --optimistic_gold: #e4a97a;
  --authoritative_red: #eb5757;
  --text_label_black: #212529;
  --white: #ffffff;
  --darkest_gray: #404a57;
  --dark_gray: #5e6a79;
  --medium_gray: #8693a5;
  --light_medium_gray: #b7c3cd;
  --light_gray: #e1e7ec;
  --lightest_gray: #f7f8f9;
  --placeholder: #6f6c90;
  --inputBorder: #b7c3cd;
  --textClr: #5e6a79;
}

// scss hash maps to generate helper classes
$colors: (
  brand_green: var(--brand_green),
  footer_blue: var(--footer_blue),
  optimistic_gold: var(--optimistic_gold),
  authoritative_red: var(--authoritative_red),
  text_label_black: var(--text_label_black),
  white: var(--white),
  darkest_gray: var(--darkest_gray),
  dark_gray: var(--dark_gray),
  medium_gray: var(--medium_gray),
  light_medium_gray: var(--light_medium_gray),
  light_gray: var(--light_gray),
  lightest_gray: var(--lightest_gray),
) !default;

// looping through the scss hash map to generate utility classes...
// to use assign classname of .clr-{color} to give the text color same for background with .bg-{color} and same for svg with .fill-{color}
@each $color, $value in $colors {
  .clr-#{$color} {
    color: $value;
  }
  .bg-#{$color} {
    background-color: $value;
  }
  .fill-#{$color} {
    fill: $value;
  }
}
