.pd_home {
  position: relative;
  .fixed_div {
    position: absolute;
    width: 634px;
    height: 634px;
    border-radius: 50%;
    left: -365px;
    background: #e2f8e6;
    filter: blur(1000px);
    top: 762px;
    border: 2px solid red;
  }
}
