@use "../../../../assets/styleUtils/index" as *;

.pd_hero {
  width: 100%;
  height: 800px;
  .carobox {
    width: 100%;
    position: relative;
    height: 100%;
    .slick-dots {
      position: absolute !important;
      bottom: 200px;
      li {
        button {
          background: #ffffff;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          &::before {
            content: none;
          }
        }
        &.slick-active {
          button {
            border: 2px solid #28a84a;
          }
        }
      }
    }

    .each_carousel_box {
      width: 100%;
      height: 100% !important;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          90deg,
          #fbf8f8 21.42%,
          rgba(255, 255, 255, 0) 85.56%
        );
      }
      .text_box {
        position: relative;
        width: 50%;
        z-index: 1;
        padding-top: 140px;
        margin-left: 150px;
        @include mq(968px) {
          width: 80%;
          margin: auto;
        }
        .real {
          font-weight: 400;
          font-size: 22px;
          opacity: 0.6;
          color: var(--darkest_gray);
          @include mq(768px) {
            font-size: 18px;
          }
        }
        .main_text {
          font-weight: 300;
          font-size: 55px;
          line-height: 63px;
          color: rgba($color: #404a57, $alpha: 0.9);
          //   color: rgba($color: var(--darkest_gray), $alpha: 0.7);
          span {
            color: #28a84a;
            opacity: 1;
            font-weight: bold;
          }
          @include mq(768px) {
            font-size: 30px;
          }
        }
        .sub_text {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          margin: 20px 0 35px;
          color: rgba($color: #404a57, $alpha: 0.9);
          @include mq(768px) {
            font-size: 14px;
          }
        }
        form {
          border: 1px solid #eff0f7;
          box-shadow: 0px 8px 25px rgba(13, 10, 44, 0.06);
          border-radius: 10px;
          background: white;
          // padding: 12px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 600px;
          @include mq(768px) {
            width: 100%;
          }
          input {
            background: none;
            border: none;
            color: #6f6c90;
            &::placeholder {
              color: #6f6c90;
            }
          }
          button {
            background: #28a84a;
            box-shadow: 0px 3px 12px rgb(74 58 255 / 18%);
            border-radius: 10px;
            padding: 17px 5px;
            font-weight: 400;
            font-size: 16px;
            border: none;
            width: 100%;
            color: white;
          }
        }
      }
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-slider,
    .slick-track,
    .slick-slide,
    .slick-list {
      height: 100% !important;
    }
    .slick-slide > div {
      height: 100%;
    }
  }
}
