@use "../../../../assets/styleUtils/index" as *;
.pd_faq {
  width: 85%;
  margin: auto;
  margin-bottom: 168px;
  padding-top: 3rem;
  @include mq(768px) {
    width: 95%;
  }
  div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    overflow-y: hidden;
    overscroll-behavior: contain;
  }
  .title_faq {
    font-weight: 800;
    font-size: 71px;
    line-height: 76px;
    margin-bottom: 70px;
    letter-spacing: -0.03em;

    color: var(--darkest_gray);
    @include mq(768px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  .faq_flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include mq(768px) {
      flex-direction: column;
    }
    .left {
      width: 60%;
      .wrapper {
        width: 100%;
        .accordion {
          width: 100%;
          .item {
            width: 100%;
            background: var(--white);
            border: 1px solid var(--light_gray);
            border-radius: 10px;
            padding: 23px;
            margin-bottom: 15px;
            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              cursor: pointer;
              p {
                font-weight: 800;
                font-size: 20px;
                line-height: 20px;
                color: var(--darkest_gray);
                @include mq(600px) {
                  font-size: 16px;
                }
                @include mq(450px) {
                  font-size: 13px;
                }
              }
              img {
                @include mq(450px) {
                  width: 15px;
                }
              }
            }

            .content {
              max-height: 0;
              overflow: hidden;
              transition: all 0.5s cubic-bezier(0, 1, 0, 1);
              p {
                font-weight: 500;
                font-size: 14px;
                line-height: 31px;
                letter-spacing: -0.02em;
                color: var(--medium_gray);
                padding-top: 20px;
                width: 80%;
              }
              &.show {
                height: auto;

                max-height: 1000px;
                transition: all 0.2s cubic-bezier(1, 0, 1, 0);
              }
            }
          }
        }
      }
      @include mq(768px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .right {
      width: 37%;
      border: 1px solid var(--light_gray);
      border-radius: 10px;
      padding: 67px 32px 32px;
      text-align: center;
      height: max-content;
      @include mq(768px) {
        width: 100%;
      }
      img {
      }
      p.more {
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        /* identical to box height, or 100% */

        /* Primary Text */
        margin: 60px 0 23px;
        color: var(--darkest_gray);
      }
      p.location {
        font-weight: 400;
        font-size: 14px;
        line-height: 29px;
        margin-bottom: 25px;
        color: var(--medium_gray);
      }
      div.relative {
        position: relative;
      }
      button {
        z-index: 10;
        border: none;
        background: var(--brand_green);
        border-radius: 10px;
        width: 100%;
        max-width: 300px;
        padding: 18px 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: var(--white);
        margin-top: 45px;
      }
      ul.modal {
        z-index: 10;
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 80px;
        left: 30%;
        width: fit-content;
        background: var(--white);
        border: 1px solid var(--light_gray);
        border-radius: 10px;
        padding: 10px 30px;
        transition: all 0.5s cubic-bezier(0, 1, 0, 1);
        &.modal {
          transform: translateY(0);
        }
        li {
          text-decoration: none;
          list-style: none;
          font-weight: 500;
          margin-bottom: 2px;
          a {
            font-weight: 500;
            font-size: 18px;
            line-height: 29px;
            color: var(--medium_black);
            display: block;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: start;
            img {
              margin-right: 20px;
              color: var(--medium_black);
            }
          }
        }
      }
    }
  }
}
