@use "../../../../assets/styleUtils/index" as *;

.pd_choose {
  width: 100%;
  // margin-top: 100px;
  position: relative;
  @include mq(500px) {
    margin-top: 100px;
  }
  &::after {
    content: "";

    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 100px;
    background: linear-gradient(
      to top,
      rgba($color: #e2f8e6, $alpha: 0.6),
      white
    );
  }
  .flex_choose {
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    @include mq(968px) {
      width: 95%;
      flex-direction: column;
    }

    .text_choose {
      @include mq(968px) {
        width: 100%;
        margin-bottom: 50px;
      }
      .why {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;

        /* Brand Green */

        color: var(--brand_green);
        margin-bottom: 16px;
      }
      .title {
        font-weight: 800;
        font-size: 46px;
        line-height: 50px;
        letter-spacing: -0.03em;
        color: var(--darkest_gray);
        @include mq(500px) {
          display: none;
        }
      }
      .titlemob {
        font-weight: 800;
        font-size: 26px;
        line-height: 30px;
        letter-spacing: -0.03em;
        color: var(--darkest_gray);
        display: none;
        @include mq(500px) {
          display: block;
        }
      }
      .desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        /* or 22px */
        font-family: "Sarabun", sans-serif;
        letter-spacing: -0.02em;

        /* Primary Text */

        color: var(--darkest_gray);
        margin: 19px 0 27px;
      }
      .line {
        width: 100%;
        height: 2px;
        background: #c4c4c4;
        margin-bottom: 27px;
      }
      .cover_mark {
        .flex_mark {
          display: flex;
          align-items: center;
          margin-bottom: 13px;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: -0.02em;
            color: var(--darkest_gray);
            margin-left: 11px;
          }
        }
      }
    }
    // .image_choose {
    //   width: 400px;
    //   img {
    //     width: 100%;
    //     object-fit: fill;
    //   }
    //   @include mq(968px) {
    //     width: 60%;
    //   }
    //   @include mq(450px) {
    //     width: 100%;
    //   }

    //   position: relative;
    //   .olu,
    //   .gallery,
    //   .dispatch,
    //   .sub {
    //     position: absolute;
    //     z-index: 1;
    //     @include mq(968px) {
    //       display: none;
    //     }
    //   }
    //   .olu {
    //     left: -180px;
    //     top: 36px;
    //     width: 300px;
    //   }
    //   .sub {
    //     right: -80px;
    //     top: 121px;
    //     width: 120px;
    //   }
    //   .gallery {
    //     width: 40px;
    //     top: 275px;
    //     left: -50px;
    //   }
    //   .dispatch {
    //     width: 300px;
    //     bottom: -20px;
    //     left: -180px;
    //   }
    // }

    .image_about_side {
      width: 600px;
      position: relative;
      @include mq(970px) {
        width: 400px;
        margin-top: 30px;
      }
      @include mq(680px) {
        width: 300px;
      }
      @include mq(450px) {
        width: 100%;
      }
      .base {
        top: -25px;
        right: 260px;
        width: 50px;
        height: 50px;
        background-color: var(--brand_green);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        @include mq(970px) {
          width: 40px;
          height: 40px;
        }
        @include mq(680px) {
          right: 160px;
          width: 25px;
          height: 25px;
          top: -15px;
          img {
            width: 100%;
          }
        }
      }
      .base,
      .critical,
      .alarm {
        position: absolute;
        z-index: 1;
        // @include mq(970px) {
        //   display: none;
        // }
      }

      .critical {
        top: 60px;
        left: -130px;
        width: 250px;
        @include mq(970px) {
          width: 200px;
        }
        @include mq(680px) {
          width: 150px;
          left: -70px;
        }
        @include mq(450px) {
          left: 20px;
        }
      }
      .alarm {
        bottom: 60px;
        left: -40px;
        width: 60px;
        height: 60px;
        background: var(--brand_green);
        @include mq(970px) {
          width: 40px;
          height: 40px;
          left: -20px;
        }
        @include mq(450px) {
          left: 20px;
        }
        .cover_red {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          img {
            width: 30px;
          }
          p {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--authoritative_red);
            color: white;
            border-radius: 50%;
            @include mq(970px) {
              height: 20px;
              width: 20px;
              font-size: 13px;
            }
          }
        }
      }

      filter: drop-shadow(0px 15px 50px rgba(0, 0, 0, 0.1));
      img.dashboard {
        width: 100%;
        object-fit: fill;
      }
    }
  }
}
