.terms {
  margin-bottom: 2.5rem;
}
section {
  height: calc(100vh - 17rem);
  background: #f7f8f9;
  overflow-y: scroll;
  padding: 20px 75px;
}
@media (max-width: 767.98px) {
  section {
    height: calc(100vh - 23rem);
    padding: 10px 15px;
  }
  .terms {
    margin-bottom: 1rem;
  }
}

ol {
  counter-reset: item;
}
ol li {
  display: block;
  position: relative;
}
ol li:before {
  content: counters(item, ".") ".";
  counter-increment: item;
  position: absolute;
  margin-right: 100%;
  right: 10px; /* space between number and text */
}
li > span {
  font-weight: bold;
}
.font-warranty {
  font-weight: 700;
}

button.btn {
  background: var(--brand_green);
  border: 0.775px solid var(--brand_green);
  box-shadow: 6.2px 6.2px 18.6px rgba(2, 2, 70, 0.15);
  border-radius: 6.2px;
  width: 170px;
  font-weight: 500;
  font-size: 12.4px;
  line-height: 19px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  img {
    margin-left: 9px;
    width: 9px;
  }
  &.pay {
    width: 100%;
  }
}

.term-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-top: 2rem;
}

.header-text{
    font-size: 24px;
    text-align: center;
}
.font-18{
    font-size: 18px;
}