@use "../../../../assets/styleUtils/index" as *;

.pd_fifth_section {
  width: 100%;
  margin-bottom: 30px;

  .top_side {
    width: 100%;
    padding-top: 140px;
    position: relative;
    // background: linear-gradient(359.71deg, #E2F8E6 56.33%, rgba(226, 248, 230, 0) 99.87%);
    // background-color: #f0fbf3;
    padding-bottom: 80px;
    &::after {
      content: "";
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      position: absolute;
      background: linear-gradient(
        to top,
        #f0fbf3,
        rgba($color: #e2f8e6, $alpha: 0.6)
      );
    }
    .cover_text {
      position: relative;
      z-index: 1;
    }
    .title {
      font-weight: 800;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      color: var(--brand_green);
      margin-bottom: 11px;
    }
    .cold {
      font-weight: 500;
      font-size: 40px;
      line-height: 46px;
      text-align: center;
      color: var(--darkest_gray);
      margin-bottom: 10px;
      @include mq(668px) {
        font-size: 25px;
        line-height: 30px;
      }
    }
    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: var(--darkest_gray);
    }
  }
  .bottom_side {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
