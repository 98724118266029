* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}
button {
	cursor: pointer;
}
a {
	text-decoration: none;
}
input {
	cursor: default;
	outline: none;
}
body {
	overflow-x: hidden;
}
