@use "../../../../assets/styleUtils/index" as *;

.pd_serve {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 180px;
  position: relative;
  @include mq(968px) {
    margin-top: 320px;
  }
  @include mq(768px) {
    margin-top: 220px;
  }
  @include mq(668px) {
    margin-top: unset;
  }
  @include mq(568px) {
    padding-top: 60px;
  }
  @include mq(468px) {
    padding-top: 20px;
  }
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;

  //     bottom: 0;
  //     background: linear-gradient(
  //       90deg,
  //       rgba($color: #000000, $alpha: 1) 10%,
  //       white 90%
  //     );
  //   }
  // &::after {
  //   content: "";
  //   width: 35%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   background: linear-gradient(
  //     90deg,
  //     rgba($color: #e2f8e6, $alpha: 0.6),
  //     white
  //   );
  // }
  .cover_serve {
    width: 85%;
    position: relative;
    z-index: 1;
    p {
      text-align: center;
      font-size: 40px;
      font-weight: 600;
    }

    margin: auto;
    @include mq(768px) {
      width: 95%;
    }
    .title_serve {
      font-weight: 800;
      font-size: 20px;
      text-align: center;
      color: var(--brand_green);
      margin-bottom: 11px;
      @include mq(420px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .solution {
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: var(--darkest_gray);
      margin-bottom: 10px;
      @include mq(420px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .sub_text {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: var(--darkest_gray);
      @include mq(420px) {
        font-size: 12px;
        line-height: 17px;
      }
    }
    .cov_flex {
      width: 100%;
      overflow-x: auto;
      @include webkit2;
    }
    .flex_side {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // min-width: 1200px;
      // flex-wrap: wrap;
      margin-top: 40px;
      @include mq(1168px) {
        overflow-x: auto;
        justify-content: unset;
        width: max-content;
      }
      .each_flex {
        background: var(--white);
        border: 1px solid #dbe4e9;
        border-radius: 20px;
        width: 31%;
        text-align: center;
        padding: 70px 10px 54px;
        @include mq(1168px) {
          width: 335px;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
        @include mq(768px) {
          width: 290px;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
        @include mq(420px) {
          width: 250px;
          padding: 20px 16px;
        }

        img {
          margin-bottom: 30px;
        }
        .title {
          font-weight: 600;
          font-size: 27px;
          line-height: 30px;
          margin-bottom: 16px;
          text-align: center;
          color: var(--darkest_gray);
          @include mq(420px) {
            font-size: 16px;
            line-height: 18px;
          }
        }
        .text {
          font-weight: 300;
          font-size: 16px;
          line-height: 26px;
          height: 110px;
          /* or 162% */

          text-align: center;
          letter-spacing: -0.02em;

          /* Description Text */

          color: var(--medium_gray);
          @include mq(420px) {
            font-size: 12px;
            line-height: 16px;
            height: 90px;
          }
        }
      }
    }
  }
}
