.cursor {
  cursor: pointer;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-3 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: 0.75rem;
}

.w-100 {
  width: 100%;
}

.mt-5 {
  margin-top: 1.75rem;
}
