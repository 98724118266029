@use "../../../../assets/styleUtils/index" as *;
.pd_client {
  margin-bottom: 173px;
  .title {
    font-weight: 800;
    font-size: 46px;
    line-height: 54px;
    /* identical to box height */

    text-align: center;

    /* Primary Text */
    margin-bottom: 38px;
    color: var(--darkest_gray);
  }
  .flex_client {
    width: 100%;
    display: flex;
    overflow-x: auto;
    @include webkit2;
    img {
      margin: 0 65px;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}
