@use "../../assets/styleUtils/index" as *;
.cover_ham {
  display: none;

  flex: auto;
  justify-content: flex-end;
  padding: 10px;
  @include mq(768px) {
    display: flex;
  }
}
