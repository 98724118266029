@use "../../../../assets/styleUtils/index" as *;

.pd_application {
  margin-top: 70px;
  width: 100%;
  .title {
    font-weight: 800;
    font-size: 46px;
    line-height: 54px;

    text-align: center;
    color: var(--darkest_gray);
    margin-bottom: 35px;
    @include mq(768px) {
      font-size: 25px;
    }
  }
  .black_cover {
    width: 100%;
    background: var(--darkest_gray);
    padding: 80px 0;
    .cover_carousel {
      width: 85%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .arr {
        cursor: pointer;

        position: absolute;
        left: -25px;
        &.sec {
          transform: rotate(180deg);

          left: unset;
          right: -25px;
        }
      }

      .cover_all_carousel {
        width: 100%;
      }
      .each_carousel {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;
        @include mq(900px) {
          flex-direction: column;
        }
        .left_side {
          @include mq(900px) {
            width: 100%;
          }
          .main_text {
            font-weight: 700;
            font-size: 55px;
            line-height: 61px;
            letter-spacing: -0.03em;
            color: var(--white);
            margin-bottom: 40px;
          }
          .desc {
            font-weight: 400;
            font-size: 18px;
            line-height: 163.15%;
            display: flex;
            align-items: center;
            color: var(--white);
            margin-bottom: 60px;
          }
          button {
            background: var(--brand_green);
            border: none;
            outline: none;
            border-radius: 30px;
            width: 200px;
            padding: 6px 0;
            font-weight: 700;
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            color: var(--white);
          }
        }
        .right_img {
          width: 500px;
          @include mq(900px) {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}
