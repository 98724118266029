@use "../../../../assets/styleUtils/index" as *;

.pd_plan {
  width: 100%;
  margin-top: 100px;
  div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    overflow-y: hidden;
    overscroll-behavior: contain;
  }

  .title {
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: var(--darkest_gray);
    margin-bottom: 35px;
    @include mq(768px) {
      font-size: 25px;
    }
  }
  .toggle_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--darkest_gray);
      margin-right: 17px;
      &:last-child {
        margin-left: 17px;
        margin-right: 0;
      }
    }
    .togDiv {
      filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.15));
      .switch {
        position: relative;
        width: 45px;
        height: 15px;
        float: left;

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: var(--brand_green);

          -webkit-transition: 0.4s;
          transition: 0.4s;
          &:before {
            position: absolute;
            content: "";
            height: 22px;
            width: 22px;
            left: -2px;
            top: -4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
          /* Rounded sliders */
          &.round {
            border-radius: 34px;
          }

          &.round:before {
            border-radius: 50%;
          }
        }
        input:checked + .slider {
          // background-color: #2196F3;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px var(--white);
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
    }
  }
  .table_cover {
    width: 95%;
    margin: auto;
    background: var(--lightest_gray);
    padding: 54px 0;
    .overflow_table,
    .btn-view {
      width: 80%;
      margin: auto;
      overflow-x: auto;

      overflow-y: auto;
      @include webkit;
      @include mq(768px) {
        width: 90%;
      }
      table {
        width: 100%;
        min-width: 1000px;
        border-collapse: collapse;
        background: white;
        padding: 0 10px;
        border-spacing: 20px;
        thead {
          background: var(--lightest_gray);
          th {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            padding: 10px;
            color: var(--brand_green);
          }
        }
        tbody {
          background: white;
          border: none;

          td {
            padding: 20px 10px;
          }
          .green {
            color: var(--brand_green);
          }
          tr {
            td {
              border: none;
              border-bottom: 1px solid var(--light_gray);
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
          td,
          p,
          .amount {
            font-weight: 500;
            font-size: 16px;
            line-height: 140.62%;
            font-family: "Sarabun", sans-serif;
            color: var(--darkest_gray);
          }
          .amount {
            text-align: center;
          }

          .flex {
            a {
              margin-bottom: 0;
            }
          }
        }
        td > img {
          display: block;
          margin: auto;
        }
        .flex {
          display: flex;
          align-items: center;
          img {
            margin-left: 7px;
          }
        }
      }
      thead,
      tbody {
        display: block;
        width: 100%;
        tr {
          width: 100%;
          display: flex;
          justify-content: space-between;
          td,
          th {
            width: 15.6%;
            // @include mq(968px) {
            //   width: 350px;
            // }
          }
          .big {
            width: 22%;
            @include mq(968px) {
              width: 350px;
            }
          }
        }
      }

      tbody {
        height: 750px; /* Just for the demo          */
        overflow-y: auto; /* Trigger vertical scroll    */
        @include webkit;
      }
    }
    .relative {
      position: relative;
    }
    .btn-view {
      table {
        background: #f7f8f9 !important;
      }
      tbody {
        height: auto;
        background: #f7f8f9 !important;
      }
      tr {
        margin-top: 3rem;
      }
    }
    ul.modal {
      z-index: 999;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: -20px;
      right: 10%;
      width: fit-content;
      background: var(--white);
      border: 1px solid var(--light_gray);
      border-radius: 10px;
      padding: 5px 30px;
      transition: all 0.5s cubic-bezier(0, 1, 0, 1);
      &.modal {
        transform: translateY(0);
      }
      li {
        text-decoration: none;
        list-style: none;
        font-weight: 500;
        margin-bottom: 2px;
        a {
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          color: var(--medium_black);
          display: block;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: start;
          img {
            margin-right: 20px;
            color: var(--medium_black);
          }
        }
      }
    }
    .button_box {
      width: 80%;
      padding-right: 40px;
      margin: 60px auto 0;
      overflow-x: auto;
      @include webkit2;
      @include mq(768px) {
        width: 90%;
      }
      @include mq(968px) {
        padding-right: 0px;
      }
    }
    .cover_btn {
      display: flex;
      margin-top: 10px;
      position: relative;
      // column-gap: 68px;
      @include mq(968px) {
        margin-top: 20px;
      }
      .dropdown-menu {
        top: auto;
        left: 25%;
        margin-left: auto;
        margin-right: auto;
        border: solid 1px #28a84a;
        border-radius: 5px;
        margin-bottom: 0.6rem;
        padding: 5px 20px;
        position: absolute;
        bottom: 40px;
        background: white;
        width: fit-content;
      }
      .dropdown-item {
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        color: #28a84a;
      }
      .contact {
        color: #28a84a;
        margin-left: 8px;
      }
      .contact:hover {
        color: #28a84a;
      }
      .col-gap-1 {
        column-gap: 1rem;
      }
      .mb-2 {
        margin-bottom: 1.5rem;
      }

      button {
        border: 1px solid var(--brand_green);
        border-radius: 30px;
        outline: none;
        width: 123px;
        color: var(--brand_green);
        font-weight: 600;
        background: transparent;
        font-size: 14px;
        padding: 10px 0;
        line-height: 20px;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
        
      }
    }
  }
}
